.form {  
  padding: 4rem 10rem 0 10rem;
  @include breakpoint($tablet-md) {
    padding: 4rem 1rem 0 1rem;
  }
  h2 {
    font-size: 2.8rem;
    font-weight: 800;
    text-transform: uppercase;
    margin-bottom: 2rem;
  } 
  .klaviyo-form {
    margin-left: -5px !important;
    @include breakpoint($tablet-md) {
      margin-left: 0 !important;
    }
    input,
    .go2171984413 {
      margin-bottom: 1rem !important;
    }
    button {
      width: 150px !important; 
      @include breakpoint($tablet-md) {
        width: 100vw !important;
      }
    }
  }
}

.form-column {
  form {
    margin-left: -10px;
    .go332817621 {
      color: white;
      background-color: #38AF68 !important;
      border: 3px solid #38AF68 !important;
      padding: 16px 20px !important;
      border-radius: 0 !important;
      font-family: 'Montserrat', sans-serif !important;
      font-weight: 700 !important;
      text-transform: uppercase;
      height: auto !important;
    }
    .ql-editor {
      p {
        font-family: 'Montserrat', sans-serif !important;
      }
    }
    .ql-blank {
      @include breakpoint($tablet-sm) {
        display: none !important;
      }
    }
  }
}