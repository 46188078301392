.icon-component {
  text-align: center;
  padding: 4rem 10rem 4rem 10rem;
  @include breakpoint($tablet-md) {
    padding: 4rem 1rem 4rem 1rem;
  }
  .icon {
    height: 55px;
    margin-bottom: 1rem;
  }
  .icon-col {
    @include breakpoint($phone-lg) {
      margin: 2rem 0;
    }
  }
  h5 {
    color: $brand;
    font-weight: 600;
  }
}