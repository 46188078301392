.navbar {
  height: 70px;
  background-color: transparent !important;
  display: flex;
  align-items: center;
  margin-bottom: -70px;
  .navbar-info {
    font-size: 14px;
    font-weight: 600;
  }
  .navbar-logo {
    width: 200px !important;
    justify-self: center;
    margin: auto;
  }
  .navbar-settings {
    width: 1.2rem;
    margin-left: auto;
  }
}