.social {
  text-align: center;
  background-color: #f5f5f5;
  padding: 4rem 10rem;
  @include breakpoint($tablet-md) {
    padding: 4rem 1rem 4rem 1rem;
  }
  h2 {
    font-size: 2.2rem;
    font-weight: 800;
    text-transform: uppercase;
    margin-bottom: 2rem;
    @include breakpoint($phone-lg) {
      font-size: 1.6rem;
    }
  }
  .social-icons {
    .fab {
      font-size: 2.8rem;
      color: #585858;
      margin: .5rem;
      cursor: pointer;
      &:hover {
        color: $brand;
      }
    }
  }
}