.hero {
  height: 640px;
  background-image: url('/hero-bg.jpg');
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  @include breakpoint($phone-lg) {
    height: 100vh;
  }
  h1 {
    color: white;
    font-size: 4rem;
    font-weight: 900;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 1.2rem;
    @include breakpoint($phone-lg) {
      font-size: 2rem;
    }
    @include breakpoint($phone-md) {
      font-size: 1.6rem;
    }
  }
  h5 {
    color: white;
    text-align: center;
  }
  .btn-group {
    @include breakpoint($phone-lg) {
      display: block;
    }
    .btn {
      padding: 12px 20px;
      border-radius: 0;
      font-family: 'Montserrat', sans-serif;
      font-weight: 700;
      margin-top: 1.4rem;
      text-transform: uppercase;
      width: 200px;
      @include breakpoint($phone-lg) {
        width: 100%;
      }
      &:hover {
        background-color: black;
        border: 3px solid black; 
      }
      &-primary {
        color: white;
        background-color: $brand;
        border: 3px solid $brand; 
        margin-right: .5rem;
        @include breakpoint($phone-lg) {
          margin-right: 0;
        }
      }
      &-secondary {
        color: white;
        background-color: transparent;
        border: 3px solid white; 
        margin-left: .5rem;
        @include breakpoint($phone-lg) {
          margin-left: 0;
        }
      }
    }
  }
}