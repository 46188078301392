.intro {  
  padding: 6rem 10rem 0 10rem;
  @include breakpoint($tablet-md) {
    padding: 4rem 1rem 4rem 1rem;
  }
  h2 {
    font-size: 2.8rem;
    font-weight: 800;
    text-transform: uppercase;
    margin-bottom: 2rem;
    @include breakpoint($phone-lg) {
      font-size: 1.6rem;
    }
  } 
  p {
    text-align: justify;
    @include breakpoint($tablet-sm) {
      text-align: left;
    }
  }
  .intro-img-col {
    text-align: center;
    img {
      width: 30%;
      @include breakpoint($tablet-sm) {
        width: 100%;
      }
    }
  }
}