@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100&family=Open+Sans:wght@300&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Tinos:ital,wght@0,400;0,700;1,400;1,700&display=swap');

body,
p,
h5 {
  font-family: 'Roboto', sans-serif;
}

.grey-bg {
  background-color: #f5f5f5;
}

h1,
h2,
h3,
h6 {
  font-family: 'Montserrat', sans-serif;
}

.btn {
  padding: 12px 20px;
  border-radius: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  margin-top: 1.4rem;
  text-transform: uppercase;
  &:hover {
    background-color: black;
    border: 3px solid black; 
  }
  &-primary {
    color: white;
    background-color: $brand;
    border: 3px solid $brand; 
  }
  &-secondary {
    color: white;
    background-color: transparent;
    border: 3px solid white; 
  }
}
