.image-text {
  padding: 6rem 10rem 6rem 10rem;
  @include breakpoint($tablet-md) {
    padding: 4rem 1rem 4rem 1rem;
  }
  h2 {
    font-size: 2.2rem;
    font-weight: 800;
    text-transform: uppercase;
    @include breakpoint($phone-lg) {
      font-size: 1.6rem;
    }
  } 
  &.grey-bg {
    background-color: #f5f5f5;
  }
  &.image-right {
    .text-col {
      padding-right: 2rem;
      margin-top: -12px;
    }
  }
  &.image-left {
    .text-col {
      padding-left: 2rem;
      margin-top: 30px;
    }
  }
  .image-col {
    margin-top: 4rem;
    @include breakpoint($tablet-sm) {
      margin-top: 0;
    }
  }
}