$brand: #38AF68;

// **breakpoints**
// phone
$phone-sm: 320px;
$phone-sm-landscape: 568px;
$phone-md: 375px;
$phone-md-landscape: 812px;
$phone-lg: 414px;
$phone-lg-landscape: 736px;
$phone-xl-landscape: 812px;
// tablet
$tablet-sm: 768px;
$tablet-sm-landscape: 1024px;
$tablet-md: 992px;
$tablet-lg: 1024px;
$tablet-lg-landscape: 1366px;
// desktop
$desktop-lg: 1920px;