.footer {
  color: white;
  text-align: center;
  // box-shadow: 0px -4px 8px -6px rgba(0,0,0,.5);
  // border: 1px solid #ededed;
  background: black;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 2rem;
  h6 {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 2rem;
    a {
      color: white;
    }
  }
}